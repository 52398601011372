<template>
  <div>
    <v-title title="Add New Field"></v-title>
    <title-box>
      <div class="row">
        <div class="col col-8">
          <h1>Add New Field</h1>
        </div>
      </div>
    </title-box>
    <div class="container-fluid">
      <field-content />
    </div>

  </div>
</template>

<script>

import FieldContent from '@/components/elements/product-feed/FieldContent'

export default {
    name: 'AddField',
    components: { FieldContent },
}
</script>

<style lang="scss" scoped>
  .c-fields {
    padding: 50px;
  }
</style>
